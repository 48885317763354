import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as tf from '@tensorflow/tfjs';

import Button from '../common/button/button';
import './train.scss';
import { classesSubject, mobileNetBaseSubject, modelSubject, stages, updateAvailableStages, updateModelSubject, updateClasses } from '../../store/store';
import svgIcon from '../../assets/icons/success.svg';

export const Train = ({ setActive = () => { } }) => {
    const [progressTrain, setProgressTrain] = useState(0);
    const [classes, setClasses] = useState([])
    const [isTrainAvailable, setIsTrainAvailable] = useState(false)
    const [webcamAvailable, setWebcamAvailable] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(null);
    const [hasAttemptedAction, setHasAttemptedAction] = useState(false);
    const fileInputRef = useRef(null);

    const navigate = useNavigate();
    const handleNext = (path, stage) => {
        setActive(path, stage);
        navigate(path);
    }

    let trainingDataOutputs = [];
    let CLASS_NAMES = classesSubject.value.map((classItem) => classItem.id);
    let model = tf.sequential();
    const MOBILE_NET_INPUT_WIDTH = 224;
    const MOBILE_NET_INPUT_HEIGHT = 224;

    useEffect(() => {
        async function checkWebcamAvailability() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                stream.getTracks().forEach(track => track.stop());
                setWebcamAvailable(true);
            } catch (error) {
                setWebcamAvailable(false);
            }
        }
        const handleBeforeUnload = (e) => {
            e.preventDefault();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        classesSubject.subscribe((newState) => {
            setClasses(newState.sort((prev, current) => prev.id > current.id));
        })

        checkWebcamAvailability();

        setIsTrainAvailable(classesSubject.value.every((classItem) => classItem.photoSets.length));
    }, [])

    model.add(
        tf.layers.dense({ inputShape: [1280], units: 64, activation: "relu" })
    );
    model.add(
        tf.layers.dense({ units: CLASS_NAMES.length ? CLASS_NAMES.length : 1, activation: "softmax" })
    );
    // model.summary();
    model.compile({
        optimizer: "adam",
        loss: CLASS_NAMES.length === 2 ? "binaryCrossentropy" : "categoricalCrossentropy",
        metrics: ["accuracy"],
    });

    async function base64ArrayToImageTensorArray(base64Array) {
        const imageTensorArray = [];

        for (const base64Data of base64Array) {
            try {
                // Check if base64Data is an object with 'cropped' property
                let base64String;
                if (typeof base64Data === 'object' && base64Data.cropped) {
                    base64String = base64Data.cropped;
                } else if (typeof base64Data === 'string') {
                    base64String = base64Data;
                } else {
                    console.error('Invalid base64 data:', base64Data);
                    continue; // Skip this item and continue with the next
                }

                base64String = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
                const binaryData = new Uint8Array(atob(base64String).split('').map(char => char.charCodeAt(0)));
                const image = new Image();
                image.src = URL.createObjectURL(new Blob([binaryData], { type: 'image/png' }));

                await new Promise(resolve => image.onload = resolve);

                const resizedCanvas = document.createElement('canvas');
                resizedCanvas.width = MOBILE_NET_INPUT_WIDTH;
                resizedCanvas.height = MOBILE_NET_INPUT_HEIGHT;
                const ctx = resizedCanvas.getContext('2d');
                ctx.drawImage(image, 0, 0, MOBILE_NET_INPUT_WIDTH, MOBILE_NET_INPUT_HEIGHT);

                const imageTensor = tf.browser.fromPixels(resizedCanvas).toFloat().div(255);
                imageTensorArray.push(imageTensor);
            } catch (error) {
                console.error('Error processing image:', error);
                // Continue with the next image
            }
        }

        return imageTensorArray;
    }

    async function trainModel(e) {
        setHasAttemptedAction(true);
        try {
            setProgressTrain(50);
            const classesPhotos = classes.map((classItem) => {
                console.log('Class item:', classItem);
                const photos = classItem.photoSets.flat(2).map(photo => photo.cropped);
                console.log('Photos:', photos);
                trainingDataOutputs.push(...(new Array(photos.length).fill(classItem.id)));
                return photos;
            });
            let outputsAsTensor = tf.tensor1d(trainingDataOutputs, "int32");

            let oneHotOutputs = tf.oneHot(outputsAsTensor, classes.length > 1 ? classes.length : 2);
            const imageTensorArray = await base64ArrayToImageTensorArray(classesPhotos.flat());

            let features = []

            for (const imageTensor of imageTensorArray) {
                // DO NOT dispose of the resized tensor to avoid training issues
                const resizedImageTensor = tf.image.resizeBilinear(imageTensor, [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH]);
                const feature = mobileNetBaseSubject.value.predict(resizedImageTensor.expandDims()).squeeze();
                features.push(feature)
            }
            features = tf.stack(features)
            
            tf.util.shuffleCombo(imageTensorArray, trainingDataOutputs);


            await model.fit(features, oneHotOutputs, {
                shuffle: true,
                batchSize: 5,
                epochs: 5,
                callbacks: { onEpochEnd: () => setProgressTrain((state) => state + 10) },
            });

            setIsSuccessful(true);
            updateModelSubject(model)
            updateAvailableStages([stages.SETUP, stages.CAPTURE, stages.TRAIN, stages.PREDICT]);
            outputsAsTensor.dispose();
            oneHotOutputs.dispose();

            let combinedModel = tf.sequential();
            combinedModel.add(mobileNetBaseSubject.value);
            combinedModel.add(model);

            combinedModel.compile({
                optimizer: "adam",
                loss:
                    CLASS_NAMES.length === 2
                        ? "binaryCrossentropy"
                        : "categoricalCrossentropy",
            });

            combinedModel.summary();
        } catch (error) {
            console.error('Error in trainModel:', error);
            setProgressTrain(0);
            setIsSuccessful(false);
        }
    }

    const handleDownloadModel = async () => {
        const model = modelSubject.value;
        const classes = classesSubject.value;

        if (!model) {
            console.error('No trained model available');
            return;
        }

        try {
            // Save the model to ArrayBuffer
            const saveResults = await model.save(tf.io.withSaveHandler(async (modelArtifacts) => {
                return modelArtifacts;
            }));

            // Create a configuration object
            const config = {
                modelTopology: saveResults.modelTopology,
                weightSpecs: saveResults.weightSpecs,
                weightData: Array.from(new Uint8Array(saveResults.weightData)),
                classes: classes.map(c => c.name),
                modelType: 'tfjs'
            };

            // Convert the configuration to a JSON string
            const configString = JSON.stringify(config);

            // Create a Blob with the configuration
            const blob = new Blob([configString], { type: 'application/json' });

            // Create a download link and trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'trained_model_config.json';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error saving model:', error);
        }
    };

    const handleLoadModel = (event) => {
        setHasAttemptedAction(true);
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                setProgressTrain(50); // Start progress
                const contents = e.target.result;
                const config = JSON.parse(contents);

                // Recreate the model from the saved data
                const loadedModel = await tf.loadLayersModel(tf.io.fromMemory(
                    config.modelTopology,
                    config.weightSpecs,
                    new Uint8Array(config.weightData).buffer
                ));

                // Compile the model
                loadedModel.compile({
                    optimizer: "adam",
                    loss: config.classes.length === 2 ? "binaryCrossentropy" : "categoricalCrossentropy",
                    metrics: ["accuracy"],
                });

                // Update the model in the store
                updateModelSubject(loadedModel);

                // Update the classes
                const updatedClasses = config.classes.map((className, index) => ({
                    id: index,
                    name: className,
                    photoSets: [],
                    imagesAmount: 0
                }));
                updateClasses(updatedClasses);
                setClasses(updatedClasses);

                // Update available stages
                updateAvailableStages([stages.SETUP, stages.CAPTURE, stages.TRAIN, stages.PREDICT]);

                setProgressTrain(100); // Complete progress
                setIsSuccessful(true);
                setIsTrainAvailable(true);
                console.log('Model loaded successfully');
            } catch (error) {
                console.error('Error loading model:', error);
                setProgressTrain(0);
                setIsSuccessful(false);
            }
        };
        reader.readAsText(file);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="train">
            <div className="panel checklist-and-classes-panel">
                <div className="checklist-section">
                    <h3>Training Checklist</h3>
                    <div className="rule">
                        <div className={classes.length > 0 ? 'check-wrapper' : 'check-wrapper wrong'}>
                            {classes.length > 0 ? <i className="bi bi-check"></i> : <i className="bi bi-x"></i>}
                        </div>
                        <p>Add classes</p>
                    </div>
                    <div className="rule">
                        <div className={webcamAvailable || classesSubject.value.every((classItem) => classItem.imagesAmount) ? 'check-wrapper' : 'check-wrapper wrong'}>
                            {webcamAvailable || classesSubject.value.every((classItem) => classItem.imagesAmount) ? <i className="bi bi-check"></i> : <i className="bi bi-x"></i>}
                        </div>
                        <p>Enable webcam and allow access when asked</p>
                    </div>
                    <div className="rule">
                        <div className={classes.length > 0 && isTrainAvailable ? 'check-wrapper' : 'check-wrapper wrong'}>
                            {classes.length > 0 && isTrainAvailable ? <i className="bi bi-check"></i> : <i className="bi bi-x"></i>}
                        </div>
                        <p>Add training samples for every class</p>
                    </div>
                </div>

                <div className="image-classes-section">
                    <h3>Image Classes</h3>
                    <div className="classes">
                        {classes.length > 0 ? (
                            classes.map((classItem, idx) => (
                                <div key={idx} className="class-item">
                                    <img src={classItem.src || "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"} alt="NC" />
                                    <div className="class-item__info">
                                        <p className="name">{classItem.name}</p>
                                        <p className="amount-photo">{classItem.imagesAmount} images</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-classes-filler">
                                <i className="bi bi-image"></i>
                                <p>No classes added yet</p>
                                <p>Add classes in the Setup stage to get started</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="panel training-panel">
                <h3>Model Training</h3>
                <p className="description">Train your model with the collected image data. This process may take a few minutes depending on the amount of data.</p>
                <div className="progress-wrapper">
                    {
                        isSuccessful ?
                            (
                                <div className='progress-wrapper__success'>
                                    <img src={svgIcon} alt="Success" />
                                </div>
                            )
                            :
                            (
                                <>
                                    <p className='progress-wrapper__number'>{progressTrain}%</p>
                                    <div className="progress">
                                        <div style={{ width: `${progressTrain}%` }} className="progress-bar" role="progressbar" aria-valuenow={progressTrain} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    {hasAttemptedAction && isSuccessful === false && progressTrain === 0 ? (
                                        <p className='progress-wrapper__start wrong'>Error while training/loading the model</p>
                                    ) : null}
                                    {classes.length < 2 ? (
                                        <p className='progress-wrapper__start wrong'>There must be at least 2 classes</p>
                                    ) : (
                                        <p className='progress-wrapper__start'>
                                            {progressTrain > 0 ? 'A few minutes more...' : 'Start training now'}
                                        </p>
                                    )}
                                </>
                            )
                    }
                </div>
                <Button 
                    text="Train & Predict" 
                    status={classes.length < 2 || !isTrainAvailable || (!webcamAvailable || !classesSubject.value.every((classItem) => classItem.imagesAmount)) || progressTrain || isSuccessful} 
                    styling='primary' 
                    handler={(e) => trainModel(e)} 
                />
            </div>

            <div className="panel model-actions-panel">
                <h3>Model Management</h3>
                <p className="description">Download your trained model for future use or load a previously trained model.</p>
                <div className="model-actions">
                    <Button 
                        text="Download Model" 
                        styling='secondary' 
                        handler={handleDownloadModel}
                        className="download-model"
                        status={!isSuccessful}
                    />
                    <Button 
                        text="Load Model" 
                        styling='secondary' 
                        handler={triggerFileInput}
                        className="load-model"
                    />
                </div>
                <input 
                    type="file" 
                    ref={fileInputRef} 
                    style={{ display: 'none' }} 
                    onChange={handleLoadModel} 
                    accept=".json"
                />
            </div>

            <div className="navigation-buttons">
                <Button text="Back" styling='secondary' handler={() => handleNext('/capture', stages.CAPTURE)} />
                <Button 
                    text="Next" 
                    status={!isSuccessful} 
                    styling='primary' 
                    handler={() => handleNext('/predict', stages.PREDICT)} 
                />
            </div>
        </div>
    );
}

export default Train;