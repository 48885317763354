import React, { useEffect, useState } from 'react';
import {
    Route,
    Routes,
    Link,
    useNavigate,
} from 'react-router-dom';
import * as tf from '@tensorflow/tfjs';
import AISeeLogo from '../assets/icons/AI-SEE-logo.svg';

import './App.scss';
import Setup from './setup/setup';
import Capture from './capture/capture';
import Train from './train/train';
import Predict from './predict/predict';
import { availableStagesSubject, currentClassSubject, mobilenetSubject, stages, updateMobileNetBaseSubject, updateMobilenetSubject } from '../store/store';
import companyLogo from '../assets/icons/elunic.png';


export const App = () => {
    const links = [
        { path: '/', text: 'Setup', stage: stages.SETUP },
        { path: '/capture', text: 'Capture', stage: stages.CAPTURE },
        { path: '/train', text: 'Train', stage: stages.TRAIN },
        { path: '/predict', text: 'Predict', stage: stages.PREDICT },
    ];
    const [active, setActive] = useState('/');
    const [availablePages, setAvailablePages] = useState([stages.SETUP, stages.TRAIN]) // Include TRAIN stage by default
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function loadMobileNetFeatureModel() {
        setLoading(true);
        const MOBILE_NET_INPUT_WIDTH = 224;
        const MOBILE_NET_INPUT_HEIGHT = 224;
        const URL =
            "https://storage.googleapis.com/jmstore/TensorFlowJS/EdX/SavedModels/mobilenet-v2/model.json";
        updateMobilenetSubject(await tf.loadLayersModel(URL));

        // mobilenetSubject.value.summary(null, null);

        const layer = mobilenetSubject.value.getLayer("global_average_pooling2d_1");
        updateMobileNetBaseSubject(tf.model({ inputs: mobilenetSubject.value.inputs, outputs: layer.output }));
        // mobileNetBaseSubject.value.summary();

        tf.tidy(function () {
            let answer = mobilenetSubject.value.predict(
                tf.zeros([1, MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH, 3])
            );
            console.log(answer.shape);
        });
        setLoading(false);
    }

    
    useEffect(() => {
        // Prevent zooming on mobile devices
        const metaViewport = document.querySelector('meta[name=viewport]');
        metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

        if (availablePages.length === 1) {
            navigate('/');
        }
    }, [availablePages, navigate]); 

    useEffect(() => {
        loadMobileNetFeatureModel();

        const subscriptionActiveClass = currentClassSubject.subscribe((currentClass) => {
            if (Object.keys(currentClass).length) {
                setAvailablePages([stages.SETUP, stages.CAPTURE, stages.TRAIN]);

                if (currentClass.imagesAmount) {
                    setAvailablePages([stages.SETUP, stages.CAPTURE, stages.TRAIN, stages.PREDICT])
                }
            }
        });

        const subscriptionAvailableStages = availableStagesSubject.subscribe((newState) => {
            // Ensure TRAIN stage is always included
            setAvailablePages([...new Set([...newState, stages.TRAIN])]);
        });

        return () => {
            // Use the same query selector in the cleanup function
            const cleanupMetaViewport = document.querySelector('meta[name=viewport]');
            if (cleanupMetaViewport) {
                cleanupMetaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
            }
            subscriptionActiveClass.unsubscribe();
            subscriptionAvailableStages.unsubscribe();
        };
    }, []);

    const isAvailablePage = (stage) => {
        return availablePages.includes(stage);
    }

    const handleClick = (pageName, stage) => {
        if (isAvailablePage(stage)) {
            setActive(pageName);
        }
    };

    return (
        <div className="app-container">
            {
                loading ? 
                (<div className='loading'>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <img src={AISeeLogo} alt="AI-SEE Logo" className="ai-see-logo loading-logo"/>
                </div>)
                :
                (<>
                    <div className="header">
                        <img src={AISeeLogo} alt="AI-SEE Logo" className="ai-see-logo desktop-logo"/>
                        {links.map(link => (
                            <Link
                                key={link.path}
                                onClick={() => handleClick(link.path, link.stage)}
                                className={`page-btn ${active === link.path ? "active" : ""} ${isAvailablePage(link.stage) ? "blue-link" : ""}`}
                                to={isAvailablePage(link.stage) ? link.path : '#'}
                            >
                                {link.text}
                            </Link>
                        ))}
                    </div>

                    <div className="content">
                        <Routes>
                            <Route path="/" element={< Setup setActive={(path, stage) => handleClick(path, stage)} />}></Route>
                            <Route path="/capture" element={< Capture setActive={(path, stage) => handleClick(path, stage)} />}></Route>
                            <Route path="/train" element={< Train setActive={(path, stage) => handleClick(path, stage)} />}></Route>
                            <Route path="/predict" element={< Predict setActive={(path, stage) => handleClick(path, stage)} />}></Route>
                        </Routes>
                    </div>
                    <img src={companyLogo} alt="Success" className="logo"/>
                </>)
            }
        </div>
    );
}

