import { BehaviorSubject } from 'rxjs';

export const stages = {
  SETUP: 1,
  CAPTURE: 2,
  TRAIN: 3,
  PREDICT: 4,
}


const mobilenetSubject = new BehaviorSubject(undefined);
const mobileNetBaseSubject = new BehaviorSubject(undefined);
const classesSubject = new BehaviorSubject([]);
const currentClassSubject = new BehaviorSubject({}); 
const availableStagesSubject = new BehaviorSubject([stages.SETUP]);
const modelSubject = new BehaviorSubject(undefined);

export function updateModelSubject(newState) {
  modelSubject.next(newState);
}

export function updateClasses(newState) {
  classesSubject.next(newState);
}

export function updateAvailableStages(newState) {
  availableStagesSubject.next(newState);
}

export function updateCurrentClass(newState) {
  currentClassSubject.next(newState);
}


export function updateMobileNetBaseSubject(newState) {
  mobileNetBaseSubject.next(newState);
}


export function updateMobilenetSubject(newState) {
  mobilenetSubject.next(newState);
}

export { classesSubject, availableStagesSubject, currentClassSubject, mobileNetBaseSubject, mobilenetSubject, modelSubject };
