import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Cookies from 'js-cookie';  // Add this import

import Button from '../common/button/button';
import './capture.scss';
import { classesSubject, currentClassSubject, stages, updateClasses, updateCurrentClass } from '../../store/store';
import svgIconTakePhoto from '../../assets/icons/take-photo-btn.svg';
import svgIconTakePhotoRed from '../../assets/icons/take-photo-btn-red.svg';

function Capture({ setActive = () => { } }) {
    const videoRef = useRef(null);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [availableDevices, setAvailableDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(Cookies.get('selectedDevice') || 'user');
    const [capturedPhotoSets, setCapturedPhotoSets] = useState([]);
    const [currentClass, setCurrentClass] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isFlashing, setIsFlashing] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const { isMobile, isTablet } = detectDevice();
        setIsMobile(isMobile);
        setIsTablet(isTablet);
        setCurrentClass(currentClassSubject.value);
    }, [])

    useEffect(() => {
        setCapturedPhotoSets(currentClass.photoSets);
    }, [currentClass])

    function detectDevice() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|android/.test(userAgent);
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return { isMobile, isTablet };
    }

    useEffect(() => {
        async function startCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setAvailableDevices(videoDevices);

                if (videoDevices.length > 0) {
                    let stream;
                    if (isMobile || isTablet) {
                        stream = await navigator.mediaDevices.getUserMedia({
                            video: { facingMode: selectedDevice === 'user' ? 'user' : 'environment' }
                        });
                    } else {
                        const savedDevice = Cookies.get('selectedDevice');
                        const deviceToUse = savedDevice && videoDevices.find(d => d.deviceId === savedDevice)
                            ? savedDevice
                            : videoDevices[0].deviceId;
                        setSelectedDevice(deviceToUse);
                        stream = await navigator.mediaDevices.getUserMedia({
                            video: { deviceId: deviceToUse }
                        });
                    }
                    setIsCameraActive(true);
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                }
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }

        startCamera();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [selectedDevice, isMobile, isTablet]);

    // Add this useEffect to save the selected device to a cookie
    useEffect(() => {
        Cookies.set('selectedDevice', selectedDevice, { expires: 365 });
    }, [selectedDevice]);

    const handleNext = (path, stage) => {
        setActive(path, stage);
        navigate(path);
    };

    const handleBack = (path, stage) => {
        updateCurrentClass({});
        setActive(path, stage);
        navigate(path);
    };

    const cropImageToSquare = (imageUrl) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const size = Math.min(img.width, img.height);
                canvas.width = size;
                canvas.height = size;

                const sourceX = (img.width - size) / 2;
                const sourceY = (img.height - size) / 2;

                ctx.drawImage(img, sourceX, sourceY, size, size, 0, 0, size, size);

                const croppedUrl = canvas.toDataURL('image/png');
                console.log('Original image dimensions:', img.width, 'x', img.height);
                console.log('Cropped image dimensions:', size, 'x', size);
                console.log('Crop source coordinates:', sourceX, sourceY);
                resolve(croppedUrl);
            };
            img.src = imageUrl;
        });
    };

    const captureFrame = async () => {
        if (videoRef.current) {
            setIsFlashing(true);
            setTimeout(() => setIsFlashing(false), 150);

            const originalPhotoSet = handleSnapshot();
            console.log('Original photo taken');
            const croppedPhotoSet = await cropImageToSquare(originalPhotoSet);
            
            const newPhotoSet = {
                original: originalPhotoSet,
                cropped: croppedPhotoSet
            };

            // Debug: Open a new tab with both original and cropped images
            // Commenting out the new tab debugging feature
            /*
            const debugWindow = window.open('', '_blank');
            debugWindow.document.write(`
                <html>
                    <head>
                        <title>Debug: Original vs Cropped</title>
                        <style>
                            body { display: flex; flex-direction: column; align-items: center; }
                            img { max-width: 100%; margin: 10px; border: 1px solid black; }
                        </style>
                    </head>
                    <body>
                        <h2>Original Image</h2>
                        <img src="${originalPhotoSet}" alt="Original">
                        <h2>Cropped Image</h2>
                        <img src="${croppedPhotoSet}" alt="Cropped">
                    </body>
                </html>
            `);
            debugWindow.document.close();
            */

            const currentPhotoSets = currentClass.photoSets ? [...currentClass.photoSets, newPhotoSet] : [newPhotoSet];
            const currentClassItem = { 
                ...currentClass, 
                imagesAmount: currentPhotoSets.length, 
                src: newPhotoSet.cropped, // Use cropped version for thumbnail
                photoSets: currentPhotoSets 
            };
            
            console.log('Updated class item, new total images:', currentClassItem.imagesAmount); // Debug log
            
            setCapturedPhotoSets(currentPhotoSets);
            setCurrentClass(currentClassItem);
            updateCurrentClass(currentClassItem);
            updateClasses([...classesSubject.value.filter((classItem) => classItem.id !== currentClass.id), currentClassItem])
        }
    };

    // Add this useEffect for debugging
    useEffect(() => {
        const subscription = currentClassSubject.subscribe(newClass => {
            console.log('Current class updated, total images:', newClass.imagesAmount);
        });
        return () => subscription.unsubscribe();
    }, []);

    const handleSnapshot = () => {
        if (videoRef.current) {
            const video = videoRef.current;
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            const snapshotUrl = canvas.toDataURL('image/png');
            console.log('Snapshot taken:', snapshotUrl);
            console.log('Snapshot dimensions:', video.videoWidth, 'x', video.videoHeight);
            return snapshotUrl;
        }
        return null;
    };

    const deleteSet = (idx) => {
        const updatedPhotoSets = [...currentClass.photoSets];
        updatedPhotoSets.splice(idx, 1);

        const updatedClassItem = {
            ...currentClass,
            imagesAmount: updatedPhotoSets.length,
            photoSets: updatedPhotoSets,
            src: updatedPhotoSets[updatedPhotoSets.length - 1]?.cropped || ''
        };

        setCapturedPhotoSets(updatedPhotoSets);
        setCurrentClass(updatedClassItem);
        updateCurrentClass(updatedClassItem);
        updateClasses([...classesSubject.value.filter((classItem) => classItem.id !== currentClass.id), updatedClassItem]);
    };

    const getSelectedSourceName = () => {
        if (isMobile || isTablet) {
            return selectedDevice === 'user' ? 'Front-facing' : 'Rear-facing';
        } else {
            const selectedDeviceObj = availableDevices.find(device => device.deviceId === selectedDevice);
            return selectedDeviceObj ? (selectedDeviceObj.label || `Camera ${selectedDeviceObj.deviceId}`) : 'Select source';
        }
    };

    return (
        <div className="capture">
            <div className="video">
                <DropdownButton 
                    id="classes-dropdown" 
                    className="classes-dropdown" 
                    title={<span>{currentClass?.name || "Select class"}</span>}
                >
                    {classesSubject.value.map((classItem) => (
                        <Dropdown.Item
                            key={classItem.id}
                            onClick={() => setCurrentClass(classItem)}
                            active={classItem.id === currentClass.id}
                        >
                            {classItem.name}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                
                {!isMobile && !isTablet && (
                    <DropdownButton 
                        id="source-dropdown" 
                        title={`Source: ${getSelectedSourceName()}`}
                        className="source-dropdown"
                    >
                        {availableDevices.map((device) => (
                            <Dropdown.Item
                                key={device.deviceId}
                                onClick={() => setSelectedDevice(device.deviceId)}
                                active={device.deviceId === selectedDevice}
                            >
                                {device.label || `Camera ${device.deviceId}`}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                )}
                
                <video ref={videoRef} id="webcam" autoPlay playsInline className="video-frame"></video>
                <div className={`flash-overlay ${isFlashing ? 'flashing' : ''}`}></div>
                
                {/* Updated Last Frames Overlay */}
                <div className="last-frames-overlay">
                    <div className="overlay-header">
                        <h4>Captured Frames</h4>
                        <p className="total-label">Total: {currentClass.imagesAmount || 0}</p>
                    </div>
                    <div className="frames__photos scrollable">
                        {currentClass.photoSets && currentClass.photoSets.length > 0 ? (
                            currentClass.photoSets.map((photoSet, setIndex) => (
                                <div key={setIndex} className="photo-wrapper">
                                    <div className="delete-wrapper" onClick={() => deleteSet(setIndex)}>
                                        <i className="bi bi-x"></i>
                                    </div>
                                    <img 
                                        src={photoSet.cropped} // Ensure we're using the cropped version
                                        alt={`Snapshot ${setIndex}`} 
                                        className="photo" 
                                        onLoad={(e) => console.log('Loaded image dimensions:', e.target.naturalWidth, 'x', e.target.naturalHeight)} // Debug log
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="no-images">
                                <i className="bi bi-camera"></i>
                                <p>No images captured yet</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='take-photo'>
                <button 
                    className={(isMobile || isTablet) && selectedDevice === "user" ? "active" : "inactive"} 
                    onClick={() => (isMobile || isTablet) && setSelectedDevice('user')}
                >
                    <p>Front-facing</p>
                </button>
                <div className='take-photo__wrapper' onClick={() => {
                    if (isCameraActive) captureFrame();
                }}>
                    <img src={svgIconTakePhoto} alt="take_photo" />
                </div>
                <button 
                    className={(isMobile || isTablet) && selectedDevice === "environment" ? "active" : "inactive"} 
                    onClick={() => (isMobile || isTablet) && setSelectedDevice('environment')}
                >
                    <p>Rear-facing</p>
                </button>
            </div>

            {/* Remove the existing frames section */}
        </div>
    );
}

export default Capture;
