import './button.scss';

export const Button = ({ text, status, styling, handler = () => {} }) => {

    return (
        <button
            disabled={status}
            className={styling + ' button-style' + (status ? ' disabled' : '')}
            onClick={($event) => handler($event)}
        >
            <p>{ text }</p>
        </button>
    );
}

export default Button;
