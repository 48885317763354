import React, { useEffect, useRef, useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import './predict.scss';
import { classesSubject, mobileNetBaseSubject, modelSubject } from '../../store/store';

export const Predict = () => {
    const videoRef = useRef(null);
    const [classes, setClasses] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('user');
    const [availableDevices, setAvailableDevices] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const [smoothedPredictions, setSmoothedPredictions] = useState([]);
    const MOBILE_NET_INPUT_WIDTH = 224;
    const MOBILE_NET_INPUT_HEIGHT = 224;
    const SMOOTHING_FACTOR = 0.50; // Reduced from 0.90 to make it update faster
    const PREDICTION_INTERVAL = 10; // Predict every 50ms instead of every frame
    const placeholderImage = "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg";

    useEffect(() => {
        const subscription = classesSubject.subscribe((newState) => {
            setClasses(newState);
            setSmoothedPredictions(new Array(newState.length).fill(0));
        });
        setIsMobile(detectBrowser());
        
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        async function startCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setAvailableDevices(videoDevices);

                if (videoDevices.length > 0) {
                    let stream;
                    if (isMobile) {
                        stream = await navigator.mediaDevices.getUserMedia({
                            video: { facingMode: selectedDevice === 'user' ? 'user' : 'environment' }
                        });
                    } else {
                        stream = await navigator.mediaDevices.getUserMedia({
                            video: { deviceId: selectedDevice || videoDevices[0].deviceId }
                        });
                    }
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                }
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }

        startCamera();

        async function startCameraAndPredictLoop() {
            try {
                await startCamera();
                await new Promise(resolve => setTimeout(resolve, 2000));
                predictLoop();
            } catch (error) {
                console.error('Error:', error);
            }
        }

        startCameraAndPredictLoop();
        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, [selectedDevice, isMobile]);

    function detectBrowser() {
        const userAgent = navigator.userAgent;
        return userAgent.match(/android/i) || userAgent.match(/iphone/i);
    }

    async function predictLoop() {
        try {
            const imageFeatures = await calculateFeaturesOnCurrentFrame();
            if (!imageFeatures) return;

            tf.tidy(() => {
                const prediction = modelSubject.value.predict(imageFeatures.expandDims()).squeeze();
                const predictionArray = prediction.arraySync();
                setPredictions(predictionArray);
                
                // Apply smoothing with exponential moving average
                setSmoothedPredictions(prevSmoothed => 
                    prevSmoothed.map((smoothed, index) => 
                        SMOOTHING_FACTOR * smoothed + (1 - SMOOTHING_FACTOR) * predictionArray[index]
                    )
                );
            });
        } catch (error) {
            console.log('predictLoop', error);
        }

        setTimeout(predictLoop, PREDICTION_INTERVAL);
    }

    async function calculateFeaturesOnCurrentFrame() {
        return tf.tidy(function() {
            try {
                const videoElement = videoRef.current;
                if (!videoElement || videoElement.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA) {
                    return null;
                }
    
                const videoFrameAsTensor = tf.browser.fromPixels(videoElement);
                const resizedTensorFrame = tf.image.resizeBilinear(
                    videoFrameAsTensor,
                    [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH],
                    true
                );
                const normalizedTensorFrame = resizedTensorFrame.div(255);
    
                return mobileNetBaseSubject.value.predict(normalizedTensorFrame.expandDims()).squeeze();
            } catch (error) {
                console.log('calculateFeaturesOnCurrentFrame', error);
            }
        })
    }

    // Add this new function to determine if the device is desktop or tablet
    const isDesktopOrTablet = () => {
        return window.innerWidth >= 768; // Adjust this breakpoint as needed
    };

    // Add this function to get the selected source name
    const getSelectedSourceName = () => {
        if (isMobile) {
            return selectedDevice === 'user' ? 'Front-facing' : 'Rear-facing';
        } else {
            const selectedDeviceObj = availableDevices.find(device => device.deviceId === selectedDevice);
            return selectedDeviceObj ? (selectedDeviceObj.label || `Camera ${selectedDeviceObj.deviceId}`) : 'Select source';
        }
    };

    return (
        <div className="predict">
            <div className="video-container">
                <div className="video">
                    <video ref={videoRef} id="webcam" autoPlay playsInline className="video-frame"></video>

                    {/* Add the floating source selector */}
                    {!isMobile && (
                        <DropdownButton 
                            id="source-dropdown" 
                            title={`Source: ${getSelectedSourceName()}`}
                            className="source-dropdown"
                        >
                            {availableDevices.map((device) => (
                                <Dropdown.Item
                                    key={device.deviceId}
                                    onClick={() => setSelectedDevice(device.deviceId)}
                                    active={device.deviceId === selectedDevice}
                                >
                                    {device.label || `Camera ${device.deviceId}`}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    )}

                    {isDesktopOrTablet() && (
                        <div className="classes-overlay">
                            {/* Move the classes content here */}
                            {classes.map((classItem, idx) => (
                                <div key={idx} className="class-item">
                                    <img src={classItem.src || placeholderImage} alt={classItem.name} />
                                    <div className="class-item__info">
                                        <div className='info'>
                                            <p className="name">{classItem.name}</p>
                                            <p className="amount">{smoothedPredictions[idx] ? Math.round((smoothedPredictions[idx] * 100)) : 0}%</p>
                                        </div>

                                        <div className="progress-wrapper">
                                            <div className="progress">
                                                <div style={{ width: `${smoothedPredictions[idx] ? Math.round(smoothedPredictions[idx] * 100) : 0}%` }} className="progress-bar" role="progressbar" aria-valuenow={smoothedPredictions[idx] ? Math.round(smoothedPredictions[idx] * 100) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* ... existing buttons and dropdowns ... */}

            {!isDesktopOrTablet() && (
                <div className="classes">
                    {/* Keep the classes content here for mobile */}
                    {classes.map((classItem, idx) => (
                        <div key={idx} className="class-item">
                            <img src={classItem.src || placeholderImage} alt={classItem.name} />
                            <div className="class-item__info">
                                <div className='info'>
                                    <p className="name">{classItem.name}</p>
                                    <p className="amount">{smoothedPredictions[idx] ? Math.round((smoothedPredictions[idx] * 100)) : 0}%</p>
                                </div>

                                <div className="progress-wrapper">
                                    <div className="progress">
                                        <div style={{ width: `${smoothedPredictions[idx] ? Math.round(smoothedPredictions[idx] * 100) : 0}%` }} className="progress-bar" role="progressbar" aria-valuenow={smoothedPredictions[idx] ? Math.round(smoothedPredictions[idx] * 100) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Predict;
