import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/button/button';
import './setup.scss';
import { updateClasses, classesSubject, stages, updateCurrentClass, updateAvailableStages, currentClassSubject } from '../../store/store';

function Setup({ setActive = () => { } }) {
  const [classes, setClasses] = useState([]);
  const [activeClassId, setActiveClassId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const subscription = classesSubject.subscribe((newState) => {
      setClasses(newState);
      if (newState.length && newState.every((classItem) => classItem.imagesAmount)) {
        updateAvailableStages([stages.SETUP, stages.CAPTURE, stages.TRAIN])
      } else {
        updateAvailableStages([stages.SETUP])
      }
    });

    updateCurrentClass({});

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleNext = (path, stage) => {
    setActive(path, stage);
    navigate(path);
  };

  const handleClassNameChange = (id, newName) => {
    const updatedClasses = classes.map(classItem => 
      classItem.id === id ? { ...classItem, name: newName } : classItem
    );
    updateClasses(updatedClasses);
    setClasses(updatedClasses);
    if (id === activeClassId) {
      updateCurrentClass(updatedClasses.find(c => c.id === id));
    }
  };

  const createClass = () => {
    const newClass = { 
      id: classes.length ? Math.max(...classes.map(c => c.id)) + 1 : 0, 
      name: 'New class', 
      src: '', 
      imagesAmount: 0, 
      photoSets: [] 
    };
    updateClasses([...classes, newClass]);
    setActiveClassId(newClass.id);
    updateCurrentClass(newClass);
  };

  const deleteClass = (id) => {
    const updatedClasses = classes.filter(c => c.id !== id);
    updateClasses(updatedClasses);
    setClasses(updatedClasses);
    if (id === activeClassId) {
      setActiveClassId(null);
      updateCurrentClass({});
    }
    if (!updatedClasses.length) {
      updateAvailableStages([stages.SETUP])
    }
  };

  return (
    <div className="setup">
      <div className="panel">
        <h2>Image Classes</h2>
        <p className="description">Add and manage your image classes here. Click on a class to select it for the next stage.</p>
        
        <div className="classes">
          {classes.map((classItem) => (
            <div 
              key={classItem.id} 
              className={`class-item ${activeClassId === classItem.id ? "active" : ""}`}
              onClick={() => {
                setActiveClassId(classItem.id);
                updateCurrentClass(classItem);
              }}
            >
              <div className="class-item__image">
                <img src={classItem.src || "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"} alt="Class" />
              </div>
              <div className="class-item__info">
                <input
                  type="text"
                  value={classItem.name}
                  onChange={(e) => handleClassNameChange(classItem.id, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
                <p className="amount-photo">{classItem.imagesAmount} images</p>
              </div>
              <button className="delete-btn" onClick={(e) => {
                e.stopPropagation();
                deleteClass(classItem.id);
              }}>
                <i className="bi bi-trash3"></i>
              </button>
            </div>
          ))}
        </div>

        <div className="add-class">
          <Button text="Add Class" styling="fancy" handler={createClass} />
        </div>
      </div>

      <div className="navigation-buttons">
        <Button 
          text="Next Step - Capture" 
          status={classes.length < 2} 
          styling="primary" 
          handler={() => handleNext('/capture', stages.CAPTURE)}
        />
      </div>
    </div>
  );
}

export default Setup;
